// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-app-project-id-index-tsx": () => import("./../../../src/pages/app/[projectId]/index.tsx" /* webpackChunkName: "component---src-pages-app-project-id-index-tsx" */),
  "component---src-pages-app-project-id-settings-tsx": () => import("./../../../src/pages/app/[projectId]/settings.tsx" /* webpackChunkName: "component---src-pages-app-project-id-settings-tsx" */),
  "component---src-pages-check-mailbox-tsx": () => import("./../../../src/pages/check-mailbox.tsx" /* webpackChunkName: "component---src-pages-check-mailbox-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-widget-tsx": () => import("./../../../src/pages/widget.tsx" /* webpackChunkName: "component---src-pages-widget-tsx" */),
  "component---src-templates-help-tsx": () => import("./../../../src/templates/help.tsx" /* webpackChunkName: "component---src-templates-help-tsx" */)
}

