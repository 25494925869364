import tailwind from "@theme-ui/preset-tailwind";

const theme = {
  ...tailwind,
  colors: {
    ...tailwind.colors,
    brandBlue: [
      "#E6EFFF",
      "#CCDFFF",
      "#005DFF",
      "#002F80",
      "#304C7C",
      "#20314E",
      "#182439",
    ],
    brandOrange: [
      "#FFF0E6",
      "#FFD2B3",
      "#FFB580",
      "#FF6A00",
      "#CC5500",
      "#662A00",
    ],
  },
  fontSizes: [12, 14, 18, 24, 34, 41, 46, 58, 72],
  fonts: {
    ...tailwind.fonts,
    Roboto: `"Roboto", "Franklin Gothic Medium", Tahoma, sans-serif`,
  },
};

export default theme;
